export const makeCompatiblePayload = (payload: Record<string, unknown>) => {
	return Object.entries(payload).reduce<Record<string, AllowedTypes>>(
		(acc, [key, value]) => {
			if (value === undefined || (Array.isArray(value) && value.length === 0))
				return acc;
			return {
				...acc,
				[key]: isAllowedType(value) ? value : JSON.stringify(value),
			};
		},
		{},
	);
};

type AllowedTypes = string | number | boolean | string[] | number[] | boolean[];

const isAllowedType = (value: unknown): value is AllowedTypes => {
	if (Array.isArray(value)) {
		return (
			value.every((v) => typeof v === 'string') ||
			value.every((v) => typeof v === 'number') ||
			value.every((v) => typeof v === 'boolean')
		);
	}

	return (
		typeof value === 'string' ||
		typeof value === 'number' ||
		typeof value === 'boolean'
	);
};
