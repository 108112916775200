enum ApiTag {
	Attribution = 'Attribution',
	AuthUser = 'AuthUser',
	AuthUserIdentifier = 'AuthUserIdentifier',
	CancelationDiscount = 'CancelationDiscount',
	Challenge = 'Challenge',
	CommunityContent = 'CommunityContent',
	CommunityReaction = 'CommunityReaction',
	DailyChallenge = 'DailyChallenge',
	DailyTask = 'DailyTask',
	EfficacyMeasurement = 'EfficacyMeasurement',
	FeatureConfig = 'FeatureConfig',
	InAppPurchase = 'InAppPurchase',
	ItoInnerCritic = 'ItoInnerCritic',
	LearnTracking = 'LearnTracking',
	Poll = 'Poll',
	Pseudonym = 'Pseudonym',
	Sanity = 'Sanity',
	SoundTracking = 'SoundTracking',
	SubscriptionStatus = 'SubscriptionStatus',
	TrackingDate = 'TrackingDate',
	ItoSession = 'ItoSession',
}

export default ApiTag;
