import LogRocket from 'logrocket';
import { useCallback, useEffect } from 'react';

import { logRocketHostName, logRocketSlug } from '../../../environment';
import { AnalyticsEvent, TrackEventHook } from '../../analytics';
import { AnalyticsProvider } from '../../analytics/types';
import { stripCher } from '../../errors/stripCher';
import usePseudonym from '../../hooks/usePseudonym';
import { getRuntimeInfo } from '../../utils/buildInfo';
import { requestUrlSanitizer } from '../../utils/requests';
import { makeCompatiblePayload } from './helpers';

export const initLogRocket = () => {
	LogRocket.init(logRocketSlug, {
		shouldCaptureIP: false,
		rootHostname: logRocketHostName,
		release: getRuntimeInfo().commitSha,
		console: {
			isEnabled: false,
		},
		dom: {
			textSanitizer: true,
			inputSanitizer: true,
		},
		network: {
			isEnabled: true,
			requestSanitizer: (request) => {
				request.url = requestUrlSanitizer(request.url);
				request.body = undefined;
				request.headers = {};

				return request;
			},
			responseSanitizer: (response) => {
				const bodyStr = response.body;

				response.body = undefined;
				response.headers = {};

				if (bodyStr && response.status && response.status >= 400) {
					try {
						const body = JSON.parse(bodyStr);
						if (typeof body?.code === 'string') {
							response.body = JSON.stringify(stripCher(body));
						}
					} catch {}
				}

				return response;
			},
		},
	});
};

const useLogRocket = () => {
	const { pseudonymId } = usePseudonym().data ?? {};

	useEffect(() => {
		if (!pseudonymId) return;
		LogRocket.identify(pseudonymId);
	}, [pseudonymId]);
};

export const endLogRocketSession = () => {
	if (!__DEV__) {
		// startNewSession fails if LogRocket wasn't initialized
		return LogRocket.startNewSession();
	}
};

export const AnalyticsProviderLogRocket: AnalyticsProvider = ({ children }) => {
	// no setup needed
	return <>{children}</>;
};

export const useTrackEventLogRocket: TrackEventHook = () => {
	return useCallback((eventName, payload) => {
		if (eventName === AnalyticsEvent.screen_viewed) return;
		LogRocket.track(eventName, makeCompatiblePayload(payload));
	}, []);
};

export default useLogRocket;
