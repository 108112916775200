import { BackgroundView, CMSUtil, UIContext } from '@wearemojo/ui-components';
import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import useCreateUIContext from '../hooks/useCreateUIContext';
import ApiClients, { ApiClient } from '../store/api/ApiClients';
import { CMSGlobalContextUIProvider } from './CMSGlobalContextProvider';

const UIProviders = ({ children }: { children: ReactNode }) => {
	const uiContext = useCreateUIContext();
	const sanityClient = ApiClients.getClient(ApiClient.sanity);

	return (
		<UIContext.Provider value={uiContext}>
			<CMSUtil.SanityContext.Provider value={sanityClient}>
				<CMSGlobalContextUIProvider>
					<AppBackground>{children}</AppBackground>
				</CMSGlobalContextUIProvider>
			</CMSUtil.SanityContext.Provider>
		</UIContext.Provider>
	);
};

const AppBackground = ({ children }: { children: ReactNode }) => {
	return (
		<BackgroundView background="background_primary" style={styles.root}>
			{children}
		</BackgroundView>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1,
	},
});

export default UIProviders;
