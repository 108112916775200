import { RetryOptions } from '@reduxjs/toolkit/query';

import { MojoQueryArgs } from './providers/mojo/mojoBaseQuery';
import { SanityQueryArgs } from './providers/sanity/sanityBaseQuery';

export enum QueryProvider {
	Mojo = 'Mojo',
	Sanity = 'Sanity',
}

export type QueryArgs =
	| ({ provider: QueryProvider.Mojo } & MojoQueryArgs)
	| ({ provider: QueryProvider.Sanity } & SanityQueryArgs);

export type ExtraOptions = Pick<RetryOptions, 'maxRetries'> & {
	retryOnError?: boolean;
};
