import { NavigationAction, useLinkProps } from '@react-navigation/native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import {
	LinkProviderOptions,
	LinkProviderType,
} from '@wearemojo/ui-components';
import React, { cloneElement, isValidElement, ReactNode } from 'react';

import { RootParamList } from './params';

export type LinkPropsBuilder<ParamList extends RootParamList> = {
	to: To<ParamList>;
	action?: NavigationAction;
} & LinkProviderOptions;

export type LinkProviderProps<ParamList extends RootParamList> =
	LinkPropsBuilder<ParamList> & {
		children: ReactNode;
	};

/*
	A way to pass and apply link props to other components/packages,
	without them being concerned with the specifics of react-navigation, so that
	they retain the ability to be used without it (storybook, test cases, etc)

	Example usage:
		const Screen = () => {
			const linkProvider = ({ children }) => (
				<LinkProvider to={{ screen: 'Home', params: {} }}>
					{children}
				</LinkProvider>
			);
			return (
				<ComponentView linkProvider={linkProvider} />
			);
		};

		const View = ({linkProvider: LinkProvider}) => (
			<LinkProvider>
				<Button title="Home" />
			</LinkProvider>
		);
*/
function LinkProvider<ParamList extends RootParamList>({
	to,
	action,
	children,
	onPressEffect,
}: LinkProviderProps<ParamList>) {
	const linkProps = useLinkProviderProps({ to, action });

	const originalOnPress = linkProps.onPress;
	linkProps.onPress = (...args) => {
		onPressEffect?.({ href: linkProps.href });
		originalOnPress(...args);
	};

	if (isValidElement(children)) {
		return <>{cloneElement(children, linkProps)}</>;
	}

	return <>{children}</>;
}

function useLinkProviderProps<ParamList extends RootParamList>({
	to,
	action,
}: LinkPropsBuilder<ParamList>) {
	const { href } = useLinkProps<ParamList>({ to, action });
	// Workaround for useLinkProps onPress only working once:
	// https://github.com/react-navigation/react-navigation/issues/10847
	return useLinkProps<ParamList>({ to: href, action });
}

export type LinkOnPressProviderProps = {
	children: ReactNode;
	onPress: () => void;
};

/*
	Similar to <LinkProvider /> but just provides/requires an onPress handler
*/
export const LinkOnPressProvider = ({
	children,
	onPress,
}: LinkOnPressProviderProps) => {
	if (isValidElement(children)) {
		const linkProps = { onPress };
		return <>{cloneElement(children, linkProps)}</>;
	}

	return <>{children}</>;
};

/*
	Convinience method:
		const linkProvider = createLinkTo<StackParamList>({
			screen: 'Course',
			params: { courseSlug },
		});

	Is the same as:
		const linkProvider = ({ children }: { children: ReactNode }) => (
			<LinkProvider to={{ screen: 'Course', params: { courseSlug } }}>
				{children}
			</LinkProvider>
		);
*/
export function createLinkTo<ParamList extends RootParamList>(
	to: To<ParamList>,
	options?: LinkProviderOptions,
): LinkProviderType {
	return ({ children }: { children: ReactNode }) => (
		<LinkProvider to={to} {...options}>
			{children}
		</LinkProvider>
	);
}

/*
	Convinience method for <LinkOnPressProvider />
*/
export function createLinkOnPress(onPress: () => void) {
	return ({ children }: { children: ReactNode }) => (
		<LinkOnPressProvider onPress={onPress}>{children}</LinkOnPressProvider>
	);
}

export default LinkProvider;
