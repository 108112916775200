import {
	ApiClient as MojoClient,
	Service,
	ServiceParams,
	ServiceResponses,
} from '@wearemojo/api-client';
import { RequestOptions } from '@wearemojo/api-client';
import Cher from '@wearemojo/cher';

import { logger } from '../../../../utils/logging';

export type MojoQueryArgs = {
	service: Service;
	endpoint: string;
	params?: any;
	options?: RequestOptions;
};

type Endpoint<ServiceKey extends Service> = keyof ServiceParams[ServiceKey] &
	keyof ServiceResponses[ServiceKey];
type Params<
	ServiceKey extends Service,
	EndpointKey extends Endpoint<ServiceKey>,
> = ServiceParams[ServiceKey][EndpointKey];

const mojoBaseQuery = async (
	client: MojoClient,
	{ service, endpoint, params, options }: MojoQueryArgs,
) => {
	const hasParams = Object.keys(params ?? {}).length > 0;
	try {
		const data = await client.request(
			service as Service,
			endpoint as Endpoint<Service>,
			hasParams ? (params as Params<Service, Endpoint<Service>>) : undefined,
			options,
		);
		return { data: data ?? true };
	} catch (error: any) {
		try {
			return { error: Cher.parse(error) };
		} catch (coercionError: any) {
			logger.captureWarning('Non-CHER error from backend request', {
				error,
				coercionError,
			});
			return {
				error: new Cher('uncoercible_backend_error', {
					error,
					coercionError,
				}),
			};
		}
	}
};

export default mojoBaseQuery;
