import { useCallback } from 'react';

import { AnalyticsProvider, TrackEventHook } from '../../analytics/types';

export const AnalyticsProviderFirebase: AnalyticsProvider = ({ children }) => {
	// no setup needed
	return <>{children}</>;
};

export const useTrackEventFirebase: TrackEventHook = () => {
	return useCallback(() => {}, []);
};
