import { useCallback } from 'react';

import { TrackEventHook } from '../../analytics';
import { AnalyticsProvider } from '../../analytics/types';

export const AnalyticsProviderAppsFlyer: AnalyticsProvider = ({ children }) => {
	// no setup needed
	return <>{children}</>;
};

export const useTrackEventAppsFlyer: TrackEventHook = () => {
	return useCallback(() => {}, []);
};
