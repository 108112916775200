import { createSlice } from '@reduxjs/toolkit';

import { AppState, Event, LoggedOutEvents } from './types';

const initialState: LoggedOutEvents = {
	events: [],
};

const loggedOutEventsSlice = createSlice({
	name: 'loggedOutEvents',
	initialState,
	reducers: {
		addLoggedOutEvent: (state, action: { payload: Event }) => {
			const event = action.payload;
			state.events.push(event);
		},
		resetLoggedOutEvents: (state) => {
			if (state.events.length === 0) {
				return state;
			}
			state.events = [];
		},
	},
});
export const { addLoggedOutEvent, resetLoggedOutEvents } =
	loggedOutEventsSlice.actions;
export const selectLoggedOutEvents = (state: AppState) =>
	state.loggedOutEvents.events;

export default loggedOutEventsSlice;
