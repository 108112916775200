import { createSlice } from '@reduxjs/toolkit';

import {
	AppState,
	EfficacyMeasurement,
	EfficacyMeasurementResponse,
} from './types';

const initialState: EfficacyMeasurement = {
	answers: [],
};

const efficacymeasurementSlice = createSlice({
	name: 'efficacymeasurement',
	initialState,
	reducers: {
		clearEfficiencyMeasurementAnswers: (state) => {
			state.answers = [];
		},
		recordEfficiencyMeasurementAnswer: (
			state,
			action: {
				payload: {
					index: number;
				} & EfficacyMeasurementResponse;
			},
		) => {
			const { index, ...payload } = action.payload;
			state.answers[index] = payload;
		},
	},
});

export const {
	clearEfficiencyMeasurementAnswers,
	recordEfficiencyMeasurementAnswer,
} = efficacymeasurementSlice.actions;

export const selectEfficacyMeasurementAnswers = (state: AppState) =>
	state.efficacymeasurement.answers;

export default efficacymeasurementSlice;
