import {
	DarkTheme as DefaultDarkTheme,
	findFocusedRoute,
	getPathFromState,
	NavigationContainer,
	NavigationState,
} from '@react-navigation/native';
import { themeColors, UITheme } from '@wearemojo/ui-constants';
import { ReactNode, useCallback, useRef } from 'react';

import { AnalyticsEvent } from '../analytics';
import { useTrackEvent } from '../analytics/trackEvent';
import linking from '../navigation/linking';
import { RootParamList } from '../navigation/params';
import ScreenKey from '../navigation/ScreenKey';
import { navigationRef } from '../utils/navigation';

const limitedTrackingScreenKeys: string[] = [
	ScreenKey.ActivityVariant,
	ScreenKey.AuthRegisterPreface,
];

const NavigationProviders = ({ children }: { children: ReactNode }) => {
	const trackEvent = useTrackEvent();
	const stateRef = useRef<NavigationState<RootParamList>>();
	const onStateChange = useCallback(
		(_state: NavigationState | undefined) => {
			if (!_state) {
				return;
			}

			const state = _state as NavigationState<RootParamList>;
			const route = findFocusedRoute(state);
			const previousState = stateRef.current;
			const previousRoute = previousState && findFocusedRoute(previousState);
			const current = getPathFromState(state, linking.config);
			stateRef.current = state;

			if (
				current === previousRoute?.path &&
				previousRoute?.path !== undefined
			) {
				return;
			}

			if (!route) {
				return;
			}

			// Track only the first view of some screens
			if (
				previousRoute?.name === route.name &&
				limitedTrackingScreenKeys.includes(route.name)
			) {
				return;
			}

			trackEvent(AnalyticsEvent.screen_viewed, {
				path: current,
				name: route.name,
				params: route.params as Record<string, unknown>,
				previous_path: previousRoute?.path,
				previous_name: previousRoute?.name,
				previous_params: previousRoute?.params,
			});
		},
		[trackEvent],
	);

	const onReady = useCallback(
		() => onStateChange(navigationRef.current?.getState()),
		[onStateChange],
	);

	return (
		<NavigationContainer
			ref={navigationRef}
			linking={linking}
			documentTitle={{ enabled: false }}
			onReady={onReady}
			onStateChange={onStateChange}
			theme={DarkTheme}
		>
			{children}
		</NavigationContainer>
	);
};

const DarkTheme = {
	...DefaultDarkTheme,
	background: themeColors[UITheme.dark].background_always_dark,
	colors: {
		...DefaultDarkTheme.colors,
		background: themeColors[UITheme.dark].background_always_dark,
	},
};

export default NavigationProviders;
