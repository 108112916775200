import React, {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

type SupportSheetContextType = {
	onPressSupport: (() => void) | undefined;
	setOnPressSupport: (handler: (() => void) | undefined) => void;
};

const SupportSheetContext = createContext<SupportSheetContextType | undefined>(
	undefined,
);

export const SupportSheetProvider = ({ children }: { children: ReactNode }) => {
	const [onPressSupport, setOnPressSupportState] = useState<
		(() => void) | undefined
	>(undefined);

	const setOnPressSupport = useCallback((handler: (() => void) | undefined) => {
		setOnPressSupportState(() => handler);
	}, []);

	const contextValues = useMemo(
		() => ({ onPressSupport, setOnPressSupport }),
		[onPressSupport, setOnPressSupport],
	);

	return (
		<SupportSheetContext.Provider value={contextValues}>
			{children}
		</SupportSheetContext.Provider>
	);
};

export const useSupportSheetContext = () => {
	const context = useContext(SupportSheetContext);
	if (context === undefined) {
		throw new Error(
			'useSupportSheetContext must be used within a SupportSheetProvider',
		);
	}
	return context;
};

export const useSupportSheet = (handler: () => void) => {
	const { setOnPressSupport } = useSupportSheetContext();

	useEffect(() => {
		setOnPressSupport(handler);
		return () => setOnPressSupport(undefined);
	}, [setOnPressSupport, handler]);
};
