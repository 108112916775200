import { createSlice } from '@reduxjs/toolkit';

import { Activity, AppState } from './types';

const initialState: Activity = {
	swapIndex: 0,
};

const activitySlice = createSlice({
	name: 'activity',
	initialState,
	reducers: {
		setSwapIndex: (
			state,
			action: { payload: { swapIndex: number; swapTrackingDate?: string } },
		) => {
			state.swapIndex = action.payload.swapIndex;
			state.swapTrackingDate = action.payload.swapTrackingDate;
		},
	},
});
export const { setSwapIndex } = activitySlice.actions;
export const selectSwapIndex = (state: AppState) => state.activity.swapIndex;
export const selectSwapTrackingDate = (state: AppState) =>
	state.activity.swapTrackingDate;

export default activitySlice;
