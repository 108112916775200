import {
	CommonActions,
	getStateFromPath,
	RouteProp,
	useIsFocused,
	useNavigation,
	useRoute,
} from '@react-navigation/native';
import {
	createNativeStackNavigator,
	NativeStackNavigationOptions,
	NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import { MainView, useUIContext, withSuspense } from '@wearemojo/ui-components';
import * as WebBrowser from 'expo-web-browser';
import { lazy, useEffect } from 'react';
import { Linking, Platform } from 'react-native';

import { useAppSelector } from '../hooks/useAppSelector';
import useIsNavigationReady from '../hooks/useIsNavigationReady';
import useNavigationRedirect from '../hooks/useNavigationRedirect';
import linking, { universalAppLinkHosts } from '../navigation/linking';
import { getToParams } from '../navigation/links';
import NavigatorKey from '../navigation/NavigatorKey';
import { RootParamList } from '../navigation/params';
import ScreenKey from '../navigation/ScreenKey';
import { selectIsLoggedIn } from '../store/session';
import { animation } from '../utils/animation';
import createScreen from '../utils/createScreen';
import { navigate, resolvePath } from '../utils/navigation';
import ActivityNavigator from './ActivityNavigator';

// Moved here so it's not affected by lazy loading.
// Need to be loaded for the socials login popup to redirect back to the app
WebBrowser.maybeCompleteAuthSession();

const Stack = createNativeStackNavigator<RootParamList>();

const screenOptions: NativeStackNavigationOptions = {
	headerShown: false,
	animation,
};

const modalScreenOptions: NativeStackNavigationOptions = {
	animation: 'default',
	presentation: 'modal',
};

const fullScreenModalOptions: NativeStackNavigationOptions = {
	...modalScreenOptions,
	presentation: 'fullScreenModal',
};

const modalGestureDisabledOptions: NativeStackNavigationOptions = {
	...modalScreenOptions,
	gestureEnabled: false,
};

const isWeb = Platform.OS === 'web';

const ReferralPartnerScreen = withSuspense(
	lazy(() => import('../screens/ReferralPartnerScreen')),
);

const AppNudgeScreen = withSuspense(
	lazy(() => import('../screens/AppNudgeScreen')),
);
const AppReviewScreen = withSuspense(
	lazy(() => import('../screens/AppReviewScreen')),
);
const AuthMobileIntroScreen = withSuspense(
	lazy(() => import('../screens/auth/AuthMobileIntroScreen')),
);
const AuthRecoverAccountScreen = withSuspense(
	lazy(() => import('../screens/auth/AuthRecoverAccountScreen')),
);
const AuthRedirectAppleScreen = withSuspense(
	lazy(() => import('../screens/auth/AuthRedirectAppleScreen')),
);
const AuthRedirectMagicLinkScreen = withSuspense(
	lazy(() => import('../screens/auth/AuthRedirectMagicLinkScreen')),
);
const AuthenticationScreen = withSuspense(
	lazy(() => import('../screens/AuthenticationScreen')),
);
const CommunityCreateTopicScreen = withSuspense(
	lazy(() => import('../screens/community/CommunityCreateTopicScreen')),
);
const CommunityExpertNoteScreen = withSuspense(
	lazy(() => import('../screens/community/CommunityExpertNoteScreen')),
);
const CommunityTopicScreen = withSuspense(
	lazy(() => import('../screens/community/CommunityTopicScreen')),
);
const ConsentScreen = withSuspense(
	lazy(() => import('../screens/ConsentScreen')),
);
const CelebrateCheckoutScreen = withSuspense(
	lazy(() => import('../screens/CelebrateCheckoutScreen')),
);
const DiscourseSsoScreen = withSuspense(
	lazy(() => import('../screens/DiscourseSsoScreen')),
);
const EfficacyQuestionsScreen = withSuspense(
	lazy(() => import('../screens/EfficacyQuestionsScreen')),
);
const AuthRegisterPrefaceScreen = withSuspense(
	lazy(() => import('../screens/flows/AuthRegisterPrefaceScreen')),
);
const WelcomeScreen = withSuspense(
	lazy(() => import('../screens/flows/WelcomeScreen')),
);
const GroupSessionsScreen = withSuspense(
	lazy(() => import('../screens/GroupSessionsScreen')),
);
const HomeRedirectScreen = withSuspense(
	lazy(() => import('../screens/HomeRedirectScreen')),
);
const NotFoundScreen = withSuspense(
	lazy(() => import('../screens/NotFoundScreen')),
);
const RedirectToAppScreen = withSuspense(
	lazy(() => import('../screens/RedirectToAppScreen')),
);
const ReferScreen = withSuspense(lazy(() => import('../screens/ReferScreen')));
const StreaksCalendarScreen = withSuspense(
	lazy(() => import('../screens/StreaksCalendarScreen')),
);
const ChallengeScreen = withSuspense(
	lazy(() => import('../screens/challenges/ChallengeScreen')),
);
const AccountNavigator = withSuspense(lazy(() => import('./AccountNavigator')));
const CancelationNavigator = withSuspense(
	lazy(() => import('./CancelationNavigator')),
);
const CheckoutNavigator = withSuspense(
	lazy(() => import('./CheckoutNavigator')),
);
const DevNavigator = withSuspense(lazy(() => import('./DevNavigator')));
const MainNavigator = withSuspense(lazy(() => import('./MainNavigator')));
const SoundNavigator = withSuspense(lazy(() => import('./SoundNavigator')));
const StreaksNavigator = withSuspense(lazy(() => import('./StreaksNavigator')));
const ChallengesNavigator = withSuspense(
	lazy(() => import('./ChallengesNavigator')),
);
const SQScoreNavigator = withSuspense(lazy(() => import('./SQScoreNavigator')));
const SwapActivityQuestionsScreen = withSuspense(
	lazy(() => import('../screens/SwapActivityQuestionsScreen')),
);
const GoalScreen = withSuspense(lazy(() => import('../screens/GoalScreen')));

const RootNavigator = () => {
	// This is to fix the issue where the app would redirect to the home screen before consent is given
	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	return (
		<MainView>
			<Stack.Navigator
				initialRouteName={
					isWeb || isLoggedIn
						? NavigatorKey.MainNavigator
						: ScreenKey.AuthMobileIntro
				}
				screenOptions={screenOptions}
			>
				<Stack.Screen
					name={NavigatorKey.MainNavigator}
					component={MainNavigator}
				/>
				<Stack.Screen
					name={ScreenKey.AuthMobileIntro}
					component={AuthMobileIntroScreen}
				/>
				<Stack.Screen
					name={ScreenKey.AuthRegisterPreface}
					component={AuthRegisterPrefaceScreen}
				/>
				<Stack.Screen name={ScreenKey.Welcome} component={WelcomeScreen} />
				<Stack.Screen
					name={ScreenKey.HomeRedirect}
					component={HomeRedirectScreen}
				/>
				<Stack.Screen
					name={ScreenKey.AuthRedirectApple}
					component={AuthRedirectAppleScreen}
				/>
				<Stack.Screen
					name={ScreenKey.AuthRecoverAccount}
					component={AuthRecoverAccountScreen}
				/>
				<Stack.Screen
					name={ScreenKey.AuthRedirectMagicLink}
					component={AuthRedirectMagicLinkScreen}
				/>
				<Stack.Screen name={ScreenKey.Auth} component={AuthenticationScreen} />
				<Stack.Screen
					name={NavigatorKey.AccountNavigator}
					component={AccountNavigator}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={NavigatorKey.CancelationNavigator}
					component={CancelationNavigator}
					options={modalGestureDisabledOptions}
				/>
				<Stack.Screen
					name={ScreenKey.Consent}
					component={ConsentScreen}
					options={modalGestureDisabledOptions}
				/>
				<Stack.Screen
					name={ScreenKey.CelebrateCheckout}
					component={CelebrateCheckoutScreen}
					options={fullScreenModalOptions}
				/>
				<Stack.Screen
					name={NavigatorKey.CheckoutNavigator}
					component={CheckoutNavigator}
					options={modalGestureDisabledOptions}
				/>
				<Stack.Screen
					name={ScreenKey.CommunityCreateTopic}
					component={CommunityCreateTopicScreen}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={ScreenKey.CommunityTopic}
					component={CommunityTopicScreen}
				/>
				<Stack.Screen
					name={ScreenKey.CommunityExpertNote}
					component={CommunityExpertNoteScreen}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={ScreenKey.ReferralPartner}
					component={ReferralPartnerScreen}
				/>
				<Stack.Screen name={ScreenKey.AppNudge} component={AppNudgeScreen} />
				<Stack.Screen
					name={ScreenKey.AppReview}
					options={modalScreenOptions}
					component={AppReviewScreen}
				/>
				<Stack.Screen
					name={ScreenKey.DiscourseSso}
					component={DiscourseSsoScreen}
				/>
				<Stack.Screen
					name={ScreenKey.Refer}
					options={modalScreenOptions}
					component={ReferScreen}
				/>
				<Stack.Screen
					name={NavigatorKey.DevNavigator}
					component={DevNavigator}
				/>
				<Stack.Screen name={ScreenKey.NotFound} component={NotFoundScreen} />
				<Stack.Screen
					name={NavigatorKey.ActivityNavigator}
					component={ActivityNavigator}
					options={fullScreenModalOptions}
				/>
				<Stack.Screen
					name={NavigatorKey.SoundNavigator}
					component={SoundNavigator}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={ScreenKey.GroupSessions}
					component={GroupSessionsScreen}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={ScreenKey.EfficacyQuestions}
					component={EfficacyQuestionsScreen}
					options={modalGestureDisabledOptions}
				/>
				<Stack.Screen
					name={ScreenKey.StreaksCalendar}
					component={StreaksCalendarScreen}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={NavigatorKey.StreaksNavigator}
					component={StreaksNavigator}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={ScreenKey.Goal}
					component={GoalScreen}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={NavigatorKey.SQScoreNavigator}
					component={SQScoreNavigator}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={ScreenKey.SwapActivityQuestions}
					component={SwapActivityQuestionsScreen}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={ScreenKey.Challenge}
					component={ChallengeScreen}
					options={modalScreenOptions}
				/>
				<Stack.Screen
					name={NavigatorKey.ChallengesNavigator}
					component={ChallengesNavigator}
					options={modalScreenOptions}
				/>
				<Stack.Screen name={ScreenKey.GoNative} component={GoNativeRedirect} />
				<Stack.Screen name={ScreenKey.GoWeb} component={GoWebRedirect} />
			</Stack.Navigator>
		</MainView>
	);
};

const GoNativeRedirect = createScreen(
	ScreenKey.GoNative,
	() => {
		const urlParams =
			useRoute<RouteProp<RootParamList, ScreenKey.GoWeb>>().params ?? {};
		const redirectLocally = !useUIContext().isWeb;

		if (redirectLocally) return <LocalRedirect urlParams={urlParams} />;

		return <RedirectToAppScreen urlParams={urlParams} />;
	},
	{ authRequirement: null },
);

const GoWebRedirect = createScreen(
	ScreenKey.GoWeb,
	() => {
		const urlParams =
			useRoute<RouteProp<RootParamList, ScreenKey.GoWeb>>().params ?? {};

		console.debug('GoWebRedirect - before useUIContext', {
			urlParams,
		});

		const redirectLocally = useUIContext().isWeb;

		console.debug('GoWebRedirect - redirectLocally', {
			redirectLocally,
			urlParams,
		});

		if (redirectLocally) return <LocalRedirect urlParams={urlParams} />;

		return <RedirectToWeb urlParams={urlParams} />;
	},
	{ authRequirement: null },
);

const RedirectToWeb = ({ urlParams }: { urlParams: { path?: string } }) => {
	const navigation = useNavigation<NativeStackNavigationProp<RootParamList>>();
	const isNavigationReady = useIsNavigationReady();
	const isFocused = useIsFocused();

	const resolvedPath = resolvePath(urlParams);
	const host = universalAppLinkHosts[0];
	const url = `https://${host}${resolvedPath}`;

	useEffect(() => {
		Linking.openURL(url);
	}, [url]);

	useEffect(() => {
		if (!isNavigationReady || !isFocused) return;

		const { screen, params } = getToParams(ScreenKey.Home, {});

		navigation.canGoBack() ? navigation.goBack() : navigate(screen, params);
	}, [navigation, isFocused, isNavigationReady]);

	return null;
};

const LocalRedirect = ({
	urlParams,
}: {
	urlParams: { path?: string } & Record<string, string>;
}) => {
	const navigation = useNavigation();
	const isNavigationReady = useIsNavigationReady();
	const isFocused = useIsFocused();
	const redirectToHome = useNavigationRedirect(NavigatorKey.MainNavigator, {
		screen: ScreenKey.Home,
	});

	const resolvedPath = resolvePath(urlParams);

	console.debug('LocalRedirect', {
		isNavigationReady,
		isFocused,
		urlParams,
		resolvedPath,
	});

	useEffect(() => {
		if (!isNavigationReady || !isFocused) return;

		const state = getStateFromPath(resolvedPath, linking.config);
		if (state) {
			console.debug('LocalRedirect - dispatch reset state', {
				state,
			});
			navigation.dispatch(CommonActions.reset(state));
		} else {
			console.debug('LocalRedirect - redirect to home', {
				state,
			});
			redirectToHome();
		}
	}, [isFocused, isNavigationReady, resolvedPath, navigation, redirectToHome]);

	return null;
};

export default RootNavigator;
