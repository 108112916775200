import { skipToken } from '@reduxjs/toolkit/query';

import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectUserId } from '../../session';
import api from '..';

const useIsStaffMember = () => {
	const userId = useAppSelector(selectUserId);

	return useEndpointQuery(
		api.endpoints.isStaffMember.useQuery(userId ? { userId } : skipToken),
	);
};

export default useIsStaffMember;
