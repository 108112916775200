import { ReactNode, useEffect, useMemo, useState } from 'react';

// @ts-ignore
import MyWorker from '../../../network.worker.js';
import { NetworkStateContext } from './_utils';

const NetworkStateProvider = ({ children }: { children: ReactNode }) => {
	const [networkType, setNetworkType] = useState<string>();

	useEffect(() => {
		if (!window.Worker) return;

		const worker = new MyWorker();

		worker.addEventListener('message', (event: MessageEvent) => {
			setNetworkType(event.data);
		});

		worker.onerror = function (error: ErrorEvent) {
			console.error('Worker error:', error.message);
		};

		worker.postMessage('');

		return () => {
			worker.terminate();
		};
	}, []);

	const contextValue = useMemo(() => ({ networkType }), [networkType]);

	return (
		<NetworkStateContext.Provider value={contextValue}>
			{children}
		</NetworkStateContext.Provider>
	);
};

export default NetworkStateProvider;
