import { Service, ServiceResponses } from '@wearemojo/api-client';
import { LoadingIndicator } from '@wearemojo/ui-components';
import { DevicePushToken } from 'expo-notifications';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

import { AnalyticsEvent } from '../../analytics';
import { useTrackEvent } from '../../analytics/trackEvent';
import { AnalyticsProvider, TrackEventHook } from '../../analytics/types';
import useIntercomConfig from '../../store/api/hooks/useIntercomConfig';
import useIntercomUserConfig from '../../store/api/hooks/useIntercomUserConfig';
import { logger } from '../logging';
import { CustomAttribute, PresentIntercomParams } from './intercom.types';
import { wrapIdentifyWithParams } from './wrapIdentify';

const IntercomDataContext = createContext({
	unreadConversationCount: 0,
});

export const AnalyticsProviderIntercom: AnalyticsProvider = ({ children }) => {
	const { data: config } = useIntercomConfig();
	const { data: userConfig } = useIntercomUserConfig();
	const [booted, setBooted] = useState(false);
	const [unreadConversationCount, setUnreadConversationCount] = useState(0);
	const dataContext = useMemo(
		() => ({
			unreadConversationCount,
		}),
		[unreadConversationCount],
	);

	return (
		<IntercomDataContext.Provider value={dataContext}>
			<IntercomProvider
				appId={config?.appId ?? ''}
				onUnreadCountChange={setUnreadConversationCount}
			>
				{booted ? children : <LoadingIndicator label="Intercom" />}
				{config && (
					<IntercomConfig
						userConfig={userConfig}
						onBoot={() => setBooted(true)}
					/>
				)}
			</IntercomProvider>
		</IntercomDataContext.Provider>
	);
};

const settings = {
	hideDefaultLauncher: true,
};

const IntercomConfig = ({
	userConfig,
	onBoot,
}: {
	userConfig?: ServiceResponses[Service.intercomconfig]['getUserConfig'];
	onBoot?: () => void;
}) => {
	const { boot, shutdown, update } = useIntercom();
	const [identify] = useState(() =>
		wrapIdentifyWithParams({
			reset: () => {
				shutdown();
				boot(settings);
			},
			identify: (identity?: { id: string; params: { userHash: string } }) =>
				identity != null &&
				update({
					...identity.params,
					userId: identity.id,
				}),
		}),
	);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;

		const bootWhenReady = () => {
			if (window.Intercom && typeof window.Intercom === 'function') {
				boot(settings);
				onBoot?.();
			} else {
				timeoutId = setTimeout(bootWhenReady, 100);
			}
		};

		bootWhenReady();

		return () => {
			clearTimeout(timeoutId);
		};
	}, [boot, onBoot]);

	useEffect(() => {
		if (userConfig) {
			identify({
				id: userConfig.userId,
				params: { userHash: userConfig.userHash },
			});
		} else {
			identify();
		}
	}, [identify, userConfig]);

	return null;
};

export const useIntercomUnreadMessagesCount = () =>
	useContext(IntercomDataContext).unreadConversationCount;

export const useTrackEventIntercom: TrackEventHook = () => {
	const { trackEvent } = useIntercom();

	// TODO: we need to be sure we don't call any methods on the intercom object
	// until `boot` has been called
	return useCallback(
		(eventName, payload) => {
			const flatPayload = Object.entries(payload).reduce(
				(acc, [key, value]) => {
					const stringified = JSON.stringify(value) ?? 'null';
					return {
						...acc,
						[key]: '[{'.includes(stringified[0]!) ? stringified : value,
					};
				},
				{},
			);
			// https://www.intercom.com/help/en/articles/175-set-up-event-tracking-in-intercom
			const name = eventName.replace(/[.$~`!@#%^&*'{}|\\'"]/g, '');
			trackEvent(name, flatPayload);
		},
		[trackEvent],
	);
};

export const useIntercomUpdateUser = () => {
	const { update } = useIntercom();

	// TODO: we need to be sure we don't call any methods on the intercom object
	// until `boot` has been called
	return useCallback(
		async (params: {
			customAttributes: { [key: string]: boolean | string | number };
		}) => {
			try {
				await Promise.resolve(update(params));
			} catch (error) {
				logger.logInfo('Error updating intercom user attributes', {
					error,
					customAttributesKeys: Object.keys(params.customAttributes),
				});
			}
		},
		[update],
	);
};

export const useSetupPushHandlingIntercom = () => {
	// notifications are not supported on the web
};

export const useSendTokenToIntercom = () => (_: DevicePushToken) => {
	// notifications are not supported on the web
};

export const usePresentIntercom = () => {
	const trackEvent = useTrackEvent();
	const intercomUpdateUser = useIntercomUpdateUser();
	const messagesCount = useIntercomUnreadMessagesCount();
	const { showNewMessage, showSpace } = useIntercom();

	return useCallback(
		async (params: PresentIntercomParams) => {
			const { context, type: conversationType, prePopulatedContent } = params;

			const customAttributes: CustomAttribute = {
				conversation_context: context,
				conversation_type: conversationType,
			};

			trackEvent(AnalyticsEvent.intercom_presented, customAttributes);

			await intercomUpdateUser({ customAttributes });

			if (prePopulatedContent) {
				showNewMessage(prePopulatedContent);
				return;
			}

			if (messagesCount) {
				showSpace('messages');
				return;
			}

			showSpace('home');
		},
		[intercomUpdateUser, messagesCount, showNewMessage, showSpace, trackEvent],
	);
};

export const useHideIntercom = () => {
	return useIntercom().hide;
};

declare global {
	interface Window {
		Intercom: Function;
	}
}
