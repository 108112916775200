import { RetryOptions } from '@reduxjs/toolkit/query';
import { BaseQueryApi, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { BackendError } from '@wearemojo/json-client';

import { ExtraOptions } from './types';

type DefinedRetryConditionFn = (
	error: FetchBaseQueryError | unknown,
	args: any,
	extraArgs: {
		attempt: number;
		baseQueryApi: BaseQueryApi;
		extraOptions?: RetryOptions & ExtraOptions;
	},
) => boolean;

const DEFAULT_MAX_RETRIES = 2;
const _500_MAX_RETRIES = 1;
const RETRIABLE_STATUS_CODES = [408, 500, 502, 503, 504];

export const retryCondition: DefinedRetryConditionFn = (
	error,
	_,
	{ attempt, extraOptions },
) => {
	if (!extraOptions?.retryOnError) return false;

	const maxRetries = extraOptions?.maxRetries ?? DEFAULT_MAX_RETRIES;
	if (attempt > maxRetries) return false;

	if (error instanceof BackendError) {
		const { responseStatusCode, meta } = error;

		if (responseStatusCode) {
			// Retry only once for 500 errors
			if (responseStatusCode === 500 && attempt > _500_MAX_RETRIES)
				return false;

			return RETRIABLE_STATUS_CODES.includes(responseStatusCode);
		}

		// Aborted due to our own api client timeout
		if ((meta as any)?.error?.name === 'AbortError') {
			return true;
		}

		// Retry "Network request failed"
		if (
			(meta as any)?.error?.name === 'TypeError' &&
			(meta as any)?.error?.message === 'Network request failed'
		) {
			return true;
		}
	}

	return false;
};
