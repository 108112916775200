// TODO: restructure this to be a hook, and explicitly decide how to handle the provider changing

// !previousId && !id - identify(undefined)
// !previousId && id - identify(identity)
// previousId && !id - reset() then identify(undefined)
// previousId && id && previousId === id - identify(identity)
// previousId && id && previousId !== id - reset() then identify(identity)
export const wrapIdentifyWithParams = <T>({
	reset,
	identify,
}: {
	reset: () => void;
	identify: (identity?: { id: string; params: T }) => void;
}) => {
	let previousId: string | undefined;

	return (identity?: { id: string; params: T }) => {
		const id = identity?.id;

		if (previousId != null && id !== previousId) {
			reset();
		}

		identify(identity);

		previousId = id;
	};
};

export const wrapIdentify = ({
	reset,
	identify,
}: {
	reset: () => void;
	identify: (id?: string) => void;
}) => {
	const base = wrapIdentifyWithParams<undefined>({
		reset,
		identify: (i) => identify(i?.id),
	});

	return (id?: string) => {
		if (id) {
			base({ id, params: undefined });
		} else {
			base();
		}
	};
};
