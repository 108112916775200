import { skipToken } from '@reduxjs/toolkit/query';

import { HandleableErrorCode } from '../../../errors/HandleableError';
import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import useHandleImpersonationAccessDenied from '../../../hooks/queries/useHandleImpersonationAccessDenied';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectUserId } from '../../session';
import api from '..';

const useCustomerioUserConfig = () => {
	const userId = useAppSelector(selectUserId);

	const res = useEndpointQuery(
		api.endpoints.getCustomerioUserConfig.useQuery(
			userId ? { userId } : skipToken,
		),
		{ customHandleErrors: [HandleableErrorCode.access_denied] },
	);

	useHandleImpersonationAccessDenied(res.error);

	return res;
};

export default useCustomerioUserConfig;
