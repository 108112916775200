import {
	createInteractionEventsContext,
	getComponentName,
	InteractionEvent,
	InteractionEventsContext,
	useInteractionEvents,
} from '@wearemojo/ui-components';
import { ComponentType, useEffect, useMemo } from 'react';

import { AnalyticsEvent } from '../analytics';
import { useTrackEvent } from '../analytics/trackEvent';

const events = Object.keys(InteractionEvent) as InteractionEvent[];

export default function withInteractionEvents<
	P extends JSX.IntrinsicAttributes,
>(Component: ComponentType<P>) {
	function WithInteractionEvents(props: P) {
		const trackEvent = useTrackEvent();
		const { on } = useInteractionEvents();

		useEffect(() => {
			const subscriptions = events.map((event) =>
				on(event, (payload) => {
					trackEvent(
						event as keyof typeof InteractionEvent as AnalyticsEvent,
						payload,
					);
				}),
			);
			return () => {
				for (const unsubscribe of subscriptions) {
					unsubscribe();
				}
			};
		}, [on, trackEvent]);

		return <Component {...props} />;
	}
	function WithInteractionEventsProvider(props: P) {
		const context = useMemo(() => createInteractionEventsContext(), []);
		return (
			<InteractionEventsContext.Provider value={context}>
				<WithInteractionEvents {...props} />
			</InteractionEventsContext.Provider>
		);
	}
	WithInteractionEventsProvider.displayName = getComponentName(Component);

	return WithInteractionEventsProvider;
}
