import {
	createNavigationContainerRef,
	ParamListBase,
} from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import isEqual from 'lodash.isequal';

import { RootParamList } from '../navigation/params';

export function resetOrPopIfPreviousRoute<T extends ParamListBase>(
	navigation: NativeStackNavigationProp<T>,
	name: any, // @TODO: fix typing
	params: any, // @TODO: fix typing
): void {
	const prev = getPreviousRoute(navigation);
	if (prev?.name === name && isEqual(prev?.params, params)) {
		navigation.pop();
		return;
	}
	navigation.reset({
		index: 0,
		routes: [{ name, params }],
	});
}

export function getPreviousRoute<T extends ParamListBase>(
	navigation: NativeStackNavigationProp<T>,
) {
	const routes = navigation.getState()?.routes;
	return (routes ?? [])[routes.length - 2];
}

export const navigationRef = createNavigationContainerRef<RootParamList>();
export function navigate(...args: any) {
	if (navigationRef.isReady()) {
		navigationRef.current?.navigate(...args);
	}
}

export const resolvePath = ({
	path,
	...extraParams
}: { path?: string } & Record<string, string>) => {
	const resolvedPath = path ?? '/';
	const paramDelimiter = resolvedPath.includes('?') ? '&' : '?';
	const params = new URLSearchParams(extraParams).toString();

	if (!params) return resolvedPath;

	return `${resolvedPath}${paramDelimiter}${params}`;
};
