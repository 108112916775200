import { skipToken } from '@reduxjs/toolkit/query';

import { HandleableErrorCode } from '../../../errors/HandleableError';
import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectUserId } from '../../session';
import api from '..';

const useSubscriptionStatus = () => {
	const userId = useAppSelector(selectUserId);
	const res = useEndpointQuery(
		api.endpoints.getSubscriptionStatus.useQuery(
			userId ? { userId, forceRefresh: false } : skipToken,
			{ refetchOnFocus: true },
		),
		{ customHandleErrors: [HandleableErrorCode.user_not_subscribed] },
	);
	if (res.error?.code === HandleableErrorCode.user_not_subscribed) {
		return {
			...res,
			data: null,
		};
	}

	return res;
};

export default useSubscriptionStatus;
