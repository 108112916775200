import { skipToken } from '@reduxjs/toolkit/query';

import { HandleableErrorCode } from '../../../errors/HandleableError';
import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import useHandleImpersonationAccessDenied from '../../../hooks/queries/useHandleImpersonationAccessDenied';
import { useAppSelector } from '../../../hooks/useAppSelector';
import platform from '../../../utils/platform';
import { selectUserId } from '../../session';
import api from '..';

const useIntercomUserConfig = () => {
	const userId = useAppSelector(selectUserId);

	const res = useEndpointQuery(
		api.endpoints.getIntercomUserConfig.useQuery(
			userId ? { userId, platform } : skipToken,
		),
		{ customHandleErrors: [HandleableErrorCode.access_denied] },
	);

	useHandleImpersonationAccessDenied(res.error);

	return res;
};

export default useIntercomUserConfig;
