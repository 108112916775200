import {
	CommonActions,
	Route,
	useNavigation,
	useRoute,
} from '@react-navigation/native';
import { LoadingIndicator, withErrorBoundary } from '@wearemojo/ui-components';
import { ComponentType, useCallback } from 'react';

import { useAppSelector } from '../hooks/useAppSelector';
import useIsNavigationReady from '../hooks/useIsNavigationReady';
import NavigatorKey from '../navigation/NavigatorKey';
import ScreenKey from '../navigation/ScreenKey';
import { selectIsLoggedIn } from '../store/session';
import withApiState from './withApiState';
import withInteractionEvents from './withInteractionEvents';
import withScreenEvents from './withScreenEvents';

type ScreenOptions = {
	// authRequirement: null means no auth requirement
	// authRequirement: 'authenticated' means user must be logged in
	// authRequirement: 'unauthenticated' means user must be logged out
	authRequirement: null | 'authenticated' | 'unauthenticated';
};

const defaultScreenOptions: ScreenOptions = {
	authRequirement: 'authenticated',
};

export default function createScreen<P extends JSX.IntrinsicAttributes>(
	name: ScreenKey,
	ScreenComponent: ComponentType<P>,
	options?: ScreenOptions,
) {
	const { authRequirement } = {
		...defaultScreenOptions,
		...options,
	};

	function ScreenWithAuth(props: P) {
		const { path } = useRoute();
		const isLoggedIn = useAppSelector(selectIsLoggedIn);
		const navigation = useNavigation();

		const isNavigationReady = useIsNavigationReady();

		const redirectTo = useCallback(
			(route: Pick<Route<string>, 'name' | 'params'>) => {
				console.debug('createScreen - redirectTo', {
					routeName: route.name,
					routeParams: route.params,
				});
				navigation.dispatch(
					CommonActions.reset({
						index: 0,
						routes: [route],
					}),
				);
			},
			[navigation],
		);

		const isAuthRequired = authRequirement === 'authenticated' && !isLoggedIn;
		const isAuthNotRequired =
			authRequirement === 'unauthenticated' && isLoggedIn;

		const isLoading = !isNavigationReady || isAuthRequired || isAuthNotRequired;

		if (isAuthRequired && isNavigationReady) {
			redirectTo({
				name: ScreenKey.Auth,
				params: { mode: 'login', redirectPath: path },
			});
		}

		if (isAuthNotRequired && isNavigationReady) {
			redirectTo({
				name: NavigatorKey.MainNavigator,
				params: { screen: ScreenKey.Home },
			});
		}

		if (isLoading) return <LoadingIndicator label="Screen" />;

		return <ScreenComponent {...props} />;
	}

	ScreenWithAuth.displayName = name;

	return withErrorBoundary(
		withApiState(withInteractionEvents(withScreenEvents(ScreenWithAuth))),
	);
}
