export const initAppsFlyer = (_: boolean) => {
	// noop
};

export const registerAppsFlyerListeners = () => {
	const unregisterListeners = () => {
		// noop
	};

	return unregisterListeners;
};

export const getAppsFlyerId = (): Promise<string | undefined> => {
	return new Promise((resolve) => {
		// noop
		resolve(undefined);
	});
};
