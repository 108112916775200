import { ParamListBase, RouteProp } from '@react-navigation/native';
import {
	createNativeStackNavigator,
	NativeStackNavigationOptions,
	NativeStackNavigationProp,
} from '@react-navigation/native-stack';
import { MainView, withSuspense } from '@wearemojo/ui-components';
import { StatusBar } from 'expo-status-bar';
import { lazy } from 'react';

import ModalNavigatorHeader from '../components/ModalNavigatorHeader';
import { ActivityParamList } from '../navigation/params';
import ScreenKey from '../navigation/ScreenKey';
import { animation } from '../utils/animation';

const Stack = createNativeStackNavigator<ActivityParamList>();

const ActivityStagingScreen = withSuspense(
	lazy(() => import('../screens/activity/ActivityStagingScreen')),
);
const ActivityLearningScreen = withSuspense(
	lazy(() => import('../screens/activity/ActivityLearningScreen')),
);
const ActivityVariantScreen = withSuspense(
	lazy(() => import('../screens/activity/ActivityVariantScreen')),
);
const ActivityHelpScreen = withSuspense(
	lazy(() => import('../screens/activity/ActivityHelpScreen')),
);

const ActivityNavigator = () => (
	<MainView>
		<StatusBar hidden />
		<Stack.Navigator screenOptions={screenOptions}>
			<Stack.Screen
				name={ScreenKey.ActivityStaging}
				component={ActivityStagingScreen}
			/>
			<Stack.Screen
				name={ScreenKey.ActivityLearning}
				component={ActivityLearningScreen}
				options={learningAndVariantOptions}
			/>
			<Stack.Screen
				name={ScreenKey.ActivityVariant}
				component={ActivityVariantScreen}
				options={learningAndVariantOptions}
			/>
			<Stack.Screen
				name={ScreenKey.ActivityHelp}
				component={ActivityHelpScreen}
				options={helpOptions}
			/>
		</Stack.Navigator>
	</MainView>
);

// TODO: remove showSupportButton from options once we have a way of showing the support button on the header
// for those screens that need it
const screenOptions: NativeStackNavigationOptions = {
	header: (props) => (
		<ModalNavigatorHeader
			{...props}
			defaultParentScreen={ScreenKey.Home}
			variant="fullScreen"
			options={{
				...props.options,
				showSupportButton: true,
				transparent: true,
			}}
		/>
	),
	headerTransparent: true,
	animation,
};

const learningAndVariantOptions: NativeStackNavigationOptions = {
	header: (props) => (
		<ModalNavigatorHeader
			{...props}
			defaultParentScreen={ScreenKey.Home}
			variant="fullScreen"
			options={{
				...props.options,
				showSupportButton: false,
				transparent: true,
			}}
		/>
	),
	headerTransparent: true,
	animation,
};

const onPressBack = (
	navigation: NativeStackNavigationProp<ParamListBase>,
	route: RouteProp<ActivityParamList, ScreenKey.ActivityHelp>,
) => {
	if (navigation.canGoBack()) {
		navigation.goBack();
	} else {
		const targetScreen =
			route.params.variantId === 'learning'
				? ScreenKey.ActivityLearning
				: ScreenKey.ActivityVariant;

		navigation.navigate(targetScreen, route.params);
	}
};

const helpOptions: NativeStackNavigationOptions = {
	header: (props) => (
		<ModalNavigatorHeader
			{...props}
			defaultParentScreen={ScreenKey.Home}
			variant="fullScreen"
			options={{
				...props.options,
				onPressBack: () =>
					onPressBack(
						props.navigation,
						props.route as RouteProp<ActivityParamList, ScreenKey.ActivityHelp>,
					),
				showSupportButton: false,
				transparent: true,
			}}
		/>
	),
	headerTransparent: true,
	animation: 'slide_from_bottom',
};

export default ActivityNavigator;
