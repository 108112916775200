import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import useAppDispatch from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { AppState, Once, OnceKeyOrString } from './types';

export const useOnce = (key: OnceKeyOrString) => {
	const dispatch = useAppDispatch();
	const isDone = useAppSelector(createSelectOnce(key));
	return useMemo(
		() => ({
			isDone,
			markDone: () => {
				if (key) {
					dispatch(markDone(key));
				}
			},
		}),
		[dispatch, isDone, key],
	);
};

const initialState: Once = {
	done: {},
};

const onceSlice = createSlice({
	name: 'once',
	initialState,
	reducers: {
		markDone: (
			state,
			action: {
				payload: OnceKeyOrString;
			},
		) => {
			state.done[action.payload] = true;
		},
		resetOnceData: (state) => {
			state.done = {};
		},
		removeOnceKeys: (
			state,
			action: {
				payload: string[];
			},
		) => {
			const keysToRemove = action.payload;
			keysToRemove.forEach((key) => {
				if (state.done[key] != null) {
					delete state.done[key];
				}
			});
		},
	},
});

export const selectOnce = (state: AppState) => state.once.done;
export const createSelectOnce = (key: OnceKeyOrString) => (state: AppState) =>
	(!!key && selectOnce(state)[key]) ?? false;

export const { markDone, resetOnceData, removeOnceKeys } = onceSlice.actions;
export default onceSlice;
