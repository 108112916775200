import mixpanel from 'mixpanel-browser';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { TrackEventHook } from '../../analytics';
import { AnalyticsProvider } from '../../analytics/types';
import usePseudonym from '../../hooks/usePseudonym';
import useClientSdkConfig from '../../store/api/hooks/useClientSdkConfig';
import { logger } from '../logging';
import { wrapIdentify } from './wrapIdentify';

const MixpanelContext = createContext({
	initialized: false,
});

export const AnalyticsProviderMixpanel: AnalyticsProvider = ({ children }) => {
	const { data: clientSdkConfig } = useClientSdkConfig();
	const { pseudonymId } = usePseudonym().data ?? {};
	const [initialized, setInitialized] = useState(false);
	const [identify] = useState(() =>
		wrapIdentify({
			reset: () => mixpanel.reset(),
			identify: (id) => id != null && mixpanel.identify(id),
		}),
	);

	useEffect(() => {
		if (!clientSdkConfig || !clientSdkConfig.mixpanel.enabled) return;

		mixpanel.init(clientSdkConfig.mixpanel.projectToken, {
			api_host: clientSdkConfig.mixpanel.serverUrl,
		});
		setInitialized(true);
	}, [clientSdkConfig]);

	useEffect(() => {
		if (!initialized) return;

		identify(pseudonymId);
	}, [initialized, identify, pseudonymId]);

	const context = useMemo(() => ({ initialized }), [initialized]);

	return (
		<MixpanelContext.Provider value={context}>
			{children}
		</MixpanelContext.Provider>
	);
};

export const useTrackEventMixpanel: TrackEventHook = () => {
	const { initialized } = useContext(MixpanelContext);

	return useCallback(
		(eventName, payload) => {
			if (!initialized) {
				logger.logInfo('Mixpanel not initialized');
				return;
			}

			mixpanel.track(eventName, payload);
		},
		[initialized],
	);
};
