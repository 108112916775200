import { SanityClient } from '@sanity/client';
import Cher from '@wearemojo/cher';

import { logger } from '../../../../utils/logging';

export type SanityQueryArgs = {
	query: string;
	params?: { [key: string]: any };
};

const sanityBaseQuery = async (
	client: SanityClient,
	{ query, params }: SanityQueryArgs,
) => {
	const hasParams = params && Object.keys(params ?? {}).length > 0;
	try {
		const data = await (hasParams
			? client.fetch(query, params)
			: client.fetch(query));
		return { data };
	} catch (err) {
		const message = err instanceof Error ? err.message : String(err);
		const error = new Cher('sanity_fetch_error', { message });
		logger.captureException(error);
		return { error };
	}
};

export default sanityBaseQuery;
