import { skipToken } from '@reduxjs/toolkit/query';
import { Service, ServiceParams } from '@wearemojo/api-client';

import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectImpersonatedUserId, selectSessionId } from '../../session';
import api from '..';

const useFeaturesRaw = () => {
	const impersonatedUserId = useAppSelector(selectImpersonatedUserId);
	const sessionId = useAppSelector(selectSessionId);

	const params = (():
		| ServiceParams[Service.featureconfig]['getFeaturesRaw']
		| undefined => {
		if (impersonatedUserId) {
			return {
				userIdentifier: { type: 'user', value: impersonatedUserId },
				mustUseSession: false,
			};
		}

		if (sessionId) {
			return {
				userIdentifier: { type: 'session', value: sessionId },
				mustUseSession: true,
			};
		}
	})();

	return useEndpointQuery(
		api.endpoints.getFeaturesRaw.useQuery(params ?? skipToken, {
			refetchOnFocus: true,
		}),
	);
};

export default useFeaturesRaw;
