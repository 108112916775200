import { BackendConfig, Service, ServiceParams } from '@wearemojo/api-client';
import { ActivityMetadataType } from '@wearemojo/sanity-schema';
import { PlaybackRateType, UIFlags } from '@wearemojo/ui-components';
import { UITheme } from '@wearemojo/ui-constants';

import { LogsConfig } from '../utils/logging';
import api from './api';

export type SessionState = {
	userId: string | undefined;
	impersonatedUserId: string | undefined;
	sessionId: string | undefined;
};

export type ThemeSetting = UITheme | 'system';

export enum OnceKey {
	CommunityExplainedShown = 'CommunityExplainedShown',
	FreeUserFirstCourseCompletedShown = 'FreeUserFirstCourseCompletedShown',
	InAppReviewRequested = 'InAppReviewRequested',
	InAppReviewRequestedSecondTime = 'InAppReviewRequestedSecondTime',
	WelcomeScreenDismissed = 'WelcomeScreenDismissed',
	StreaksCommitmentViewed = 'StreaksCommitmentViewed',
}

export type OnceKeyOrString = OnceKey | string;

export type Once = {
	done: Partial<Record<OnceKeyOrString, boolean>>;
};

export type SanityConfig = {
	projectId: string;
	dataset: string;
	useCdn: boolean;
};

export type DebugFlags = {
	// remove this and use specific fields instead
	[key: string]: boolean;
};

export type DevState = {
	backendConfigOverride?: BackendConfig;
	uiFlagOverrides: Partial<UIFlags>;
	debugFlags: DebugFlags;
	cmsContent: string | null;
	cmsContentAttribute: string | null;
	showStaffKnob: boolean;
	enabledLogs: LogsConfig;
	sanityConfig: SanityConfig | null;
	promptId: string;
};

export type Navigation = {
	isShowingWelcomeScreen: boolean;
};

export type SettingsState = {
	theme?: ThemeSetting;
	playbackSpeed: PlaybackRateType;
};

export interface AppState {
	[api.reducerPath]: ReturnType<typeof api.reducer>;
	dev: DevState;
	efficacymeasurement: EfficacyMeasurement;
	navigation: Navigation;
	once: Once;
	session: SessionState;
	settings: SettingsState;
	activity: Activity;
	loggedOutEvents: LoggedOutEvents;
	challengeProgress: ChallengeProgressData;
}

export type Event = {
	action: 'recordActivityCompleted';
	data: {
		payload: ServiceParams['learntracking']['recordActivityCompleted']['params'];
		analyticsPayload: {
			title: string;
			metadata?: ActivityMetadataType;
		};
	};
};

export type LoggedOutEvents = {
	events: Event[];
};

export type EfficacyMeasurementResponse =
	ServiceParams[Service.efficacymeasurement]['submitAnswers']['responses'][number];

export type EfficacyMeasurement = {
	answers: EfficacyMeasurementResponse[];
};

export type ChallengeProgressData = {
	nearCompletionChallenges: string[];
};

export type Activity = {
	swapIndex: number;
	swapTrackingDate?: string;
};
