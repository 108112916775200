import { logger } from '../utils/logging';

// IS_LOGGED_IN_KEY needs to have a prefix that doesn't get deleted when logging out - i.e. KEY_PREFIX
const IS_LOGGED_IN_KEY = 'mojo-is-logged-in-key';
const LOGGED_IN = 'LOGGED_IN';

// The following functions are only used as obeservability tool for the logged out issue

const checkIsLoggedIn = () => {
	try {
		const value = localStorage.getItem(IS_LOGGED_IN_KEY);
		console.debug('SecureStoreLoggedInState.checkIsLoggedIn', { value });
		return value === LOGGED_IN;
	} catch (error) {
		logger.captureError('SecureStoreLoggedInState.checkIsLoggedIn', {
			error,
		});
		return false;
	}
};

const setIsLoggedIn = () => {
	console.debug('SecureStoreLoggedInState.setIsLoggedIn');
	try {
		const wasLoggedIn = checkIsLoggedIn();
		if (wasLoggedIn) {
			logger.captureWarning(
				'SecureStoreLoggedInState: Logged in without logging out',
			);
		}
		localStorage.setItem(IS_LOGGED_IN_KEY, LOGGED_IN);
	} catch (error) {
		logger.captureError('SecureStoreLoggedInState.setIsLoggedIn', {
			error,
		});
	}
};

const clearIsLoggedIn = () => {
	console.debug('SecureStoreLoggedInState.clearIsLoggedIn');
	try {
		return localStorage.setItem(IS_LOGGED_IN_KEY, '');
	} catch (error) {
		logger.captureError('SecureStoreLoggedInState.clearIsLoggedIn', {
			error,
		});
	}
};

export const SecureStoreLoggedInState = {
	checkIsLoggedIn,
	setIsLoggedIn,
	clearIsLoggedIn,
};
