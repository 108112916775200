import { AudioPlayerContext } from '@wearemojo/ui-components';
import { ReactNode } from 'react';

import createAudioPlayer from '../audio/createAudioPlayer';

const AudioPlayerProvider = ({ children }: { children: ReactNode }) => {
	const audioPlayer = createAudioPlayer();
	return (
		<AudioPlayerContext.Provider value={audioPlayer}>
			{children}
		</AudioPlayerContext.Provider>
	);
};

export default AudioPlayerProvider;
