export const requestUrlSanitizer = (requestUrl: string) => {
	if (
		!requestUrl.startsWith('https://api.mojo-') ||
		requestUrl.includes('discourse')
	) {
		try {
			const url = new URL(requestUrl);
			url.pathname = '';
			url.search = '';
			url.hash = '';
			url.username = '';
			url.password = '';
			requestUrl = url.toString();
		} catch {
			requestUrl = '';
		}
	}

	return requestUrl;
};
