import { StorageProvider } from '@wearemojo/api-client';

// do not change without care & migration
const KEY_PREFIX = '__MOJO_';

export const SecureStorage: StorageProvider = {
	getItem: async (key: string) => window.localStorage.getItem(prefixKey(key)),
	setItem: async (key: string, value: string) =>
		window.localStorage.setItem(prefixKey(key), value),
	removeItem: async (key: string) =>
		window.localStorage.removeItem(prefixKey(key)),
	reinitialize: async () => {
		for (let i = 0; i < window.localStorage.length; i++) {
			const key = window.localStorage.key(i)!;

			if (key.startsWith(KEY_PREFIX)) {
				window.localStorage.removeItem(key);
			}
		}
	},
};

const prefixKey = (key: string) => {
	if (key.startsWith(KEY_PREFIX)) return key;

	return `${KEY_PREFIX}${key}`;
};
