import { FlowType, FragmentFlowsType } from '@wearemojo/sanity-schema';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import { FragmentType } from '../content/FragmentType';
import useEndpointQuery from '../hooks/queries/useEndpointQuery';
import api from '../store/api';
import useFlowDocuments from '../store/api/hooks/useFlowDocuments';
import { resolveSanityDocuments } from '../utils/resolveSanityDocuments';
import FlowProgressProvider from './FlowProgressProvider';

type ContextProps = {
	data: {
		allFlows: FlowType[] | undefined;
		registrationPrefaceFlow: DocumentOrUndefined;
		checkoutPrefaceFlow: DocumentOrUndefined;
		welcomeFlow: DocumentOrUndefined;
	};
	isLoading: boolean;
};

type DocumentOrUndefined = FlowType | undefined;

const defaultFlow = {
	allFlows: undefined,
	registrationPrefaceFlow: undefined,
	checkoutPrefaceFlow: undefined,
	welcomeFlow: undefined,
};

const FlowContext = createContext<ContextProps>({
	data: defaultFlow,
	isLoading: true,
});

type Props = {
	children: ReactNode;
};
const FlowProvider = ({ children }: Props) => {
	const { data: flowDocuments, isLoading: isGettingFlows } = useFlowDocuments();
	const { data: fragments, isLoading: isGettingFragments } = useEndpointQuery(
		api.endpoints.getFragments.useQuery(),
	);

	const context = useMemo(() => {
		if (!flowDocuments) return { data: defaultFlow, isLoading: isGettingFlows };

		const fragment = fragments?.find(
			({ _type }) => _type === FragmentType.Flows,
		) as FragmentFlowsType | undefined;

		if (!fragment) return { data: defaultFlow, isLoading: isGettingFragments };

		const resolvedFlowDocuments = resolveSanityDocuments(flowDocuments);
		const resolveFragmentFlow = (flowType: FlowType) => {
			if ('_ref' in flowType) {
				return resolvedFlowDocuments.get(
					flowType._ref as string,
				) as DocumentOrUndefined;
			}

			return flowType;
		};

		const allFlows = Array.from(resolvedFlowDocuments.values()).filter(
			({ _type }) => _type === 'Flow',
		) as FlowType[];

		return {
			data: {
				allFlows,
				registrationPrefaceFlow: resolveFragmentFlow(
					fragment.registrationPrefaceFlow,
				),
				checkoutPrefaceFlow: resolveFragmentFlow(fragment.checkoutPrefaceFlow),
				welcomeFlow: resolveFragmentFlow(fragment.welcomeFlow),
			},
			isLoading: false,
		};
	}, [flowDocuments, fragments, isGettingFlows, isGettingFragments]);

	return (
		<FlowContext.Provider value={context}>
			<FlowProgressProvider>{children}</FlowProgressProvider>
		</FlowContext.Provider>
	);
};

export const useFlowContext = () => {
	const context = useContext(FlowContext);

	if (context === undefined) {
		throw new Error('useFlowContext must be used within a FlowProvider');
	}
	return context;
};

export default FlowProvider;
