import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';
import { DevicePushToken } from 'expo-notifications';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from 'react';

import { AnalyticsEvent, AnalyticsPayload } from '../../analytics';
import { AnalyticsProvider, TrackEventHook } from '../../analytics/types';
import useCustomerioConfig from '../../store/api/hooks/useCustomerioConfig';
import useCustomerioUserConfig from '../../store/api/hooks/useCustomerioUserConfig';
import { logger } from '../logging';

const CustomerioAnalyticsContext = createContext<AnalyticsBrowser | undefined>(
	undefined,
);

export const useCustomerioAnalytics = () =>
	useContext(CustomerioAnalyticsContext);

export const AnalyticsProviderCustomerio: AnalyticsProvider = ({
	children,
}) => {
	const { data: config } = useCustomerioConfig();
	const { data: userConfig, isLoading: userConfigIsLoading } =
		useCustomerioUserConfig();

	const analytics = useMemo(() => {
		if (!config) return;

		// Not clearly documented, but based on what we can see here:
		// https://fly.customer.io/workspaces/166977/pipelines/sources/7756/settings
		const writeKey = `${config.siteId}:${config.apiKey}`;

		const cdnURL = (() => {
			switch (config.region) {
				case 'US':
					return; // default
				case 'EU':
					return 'https://cdp-eu.customer.io';
				default:
					((_: never) => {
						throw new Error(`Unexpected region: ${_}`);
					})(config.region);
			}
		})();

		return AnalyticsBrowser.load(
			{ cdnURL, writeKey },
			{
				integrations: {
					'Customer.io In-App Plugin': {
						siteId: config.siteId,
					},
				},
			},
		);
	}, [config]);

	useEffect(() => {
		if (!analytics || userConfigIsLoading) return;

		// TODO: only reset when identifier is already set

		if (userConfig) {
			analytics.identify(userConfig.identifier);
		} else {
			analytics.reset();
		}

		return () => {
			analytics.reset();
		};
	}, [analytics, userConfig, userConfigIsLoading]);

	return (
		<CustomerioAnalyticsContext.Provider value={analytics}>
			{children}
		</CustomerioAnalyticsContext.Provider>
	);
};

export const useTrackEventCustomerio: TrackEventHook = () => {
	const analytics = useCustomerioAnalytics();

	return useCallback(
		(eventName, payload) => {
			if (!analytics) {
				logger.logInfo('Customer.io not available');
				return;
			}

			if (eventName === AnalyticsEvent.screen_viewed) {
				const { name, params } =
					payload as AnalyticsPayload[AnalyticsEvent.screen_viewed];
				analytics.screen(name, params);
				return;
			}

			analytics.track(eventName, payload);
		},
		[analytics],
	);
};

export const useCustomerioSetProfileAttributes = () => {
	const analytics = useCustomerioAnalytics();

	return useCallback(
		(attributes: Record<string, unknown>) => {
			if (!analytics) {
				logger.logInfo('Customer.io not available');
				return;
			}

			analytics.identify(attributes);
		},
		[analytics],
	);
};

export const useSendTokenToCustomerio = () => (_: DevicePushToken) => {
	// notifications are not supported on the web
};
