import { useNavigation } from '@react-navigation/native';
import {
	createScreenEventsContext,
	getComponentName,
	ScreenEvent,
	ScreenEventsContext,
	useScreenEvents,
} from '@wearemojo/ui-components';
import { ComponentType, useEffect, useMemo, useRef } from 'react';

const events = [ScreenEvent.blur, ScreenEvent.focus] as const;

export default function withScreenEvents<P extends JSX.IntrinsicAttributes>(
	Component: ComponentType<P>,
) {
	function WithScreenEvents(props: P) {
		const navigation = useNavigation();
		const { dispatch } = useScreenEvents();

		useEffect(() => {
			const subscriptions = events.map((event) =>
				navigation.addListener(event, () => dispatch(event)),
			);
			return () => {
				subscriptions.forEach((unsubscribe) => unsubscribe());
			};
		}, [navigation, dispatch]);

		const prevFocused = useRef(false);
		useEffect(() => {
			const unsubscribeState = navigation.addListener('state', () => {
				// Note that parent screens of a focused screen also show as focused
				const isFocused = navigation.isFocused();
				if (isFocused !== prevFocused.current) {
					dispatch(ScreenEvent[isFocused ? 'navigateIn' : 'navigateOut']);
				}
				prevFocused.current = isFocused;
			});

			const unsubscribeBeforeRemove = navigation.addListener(
				/*
					When popping a screen, it will be unmounted before the 'state' event
					fires, so we also use 'beforeRemove' for providing the 'navigateOut'
					event. Note that 'beforeRemove' itself can't be used alone, as it
					doesn't fire when pushing/adding to a stack
				*/
				'beforeRemove',
				() => {
					if (navigation.isFocused() && prevFocused.current) {
						dispatch(ScreenEvent.navigateOut);
						prevFocused.current = false;
					}
				},
			);
			return () => {
				unsubscribeState();
				unsubscribeBeforeRemove();
			};
		}, [navigation, dispatch]);

		return <Component {...props} />;
	}
	function WithScreenEventsProvider(props: P) {
		const context = useMemo(() => createScreenEventsContext(), []);
		return (
			<ScreenEventsContext.Provider value={context}>
				<WithScreenEvents {...props} />
			</ScreenEventsContext.Provider>
		);
	}
	WithScreenEventsProvider.displayName = getComponentName(Component);

	return WithScreenEventsProvider;
}
