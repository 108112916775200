import { createSlice } from '@reduxjs/toolkit';

import { AppState, ChallengeProgressData } from './types';

const initialState: ChallengeProgressData = {
	nearCompletionChallenges: [],
};

const challengeProgress = createSlice({
	name: 'challengeProgress',
	initialState,
	reducers: {
		addNearCompletionChallenge: (state, action) => {
			if (!state.nearCompletionChallenges.includes(action.payload)) {
				state.nearCompletionChallenges.push(action.payload);
			}
		},
		removeChallenge: (state, action) => {
			state.nearCompletionChallenges = state.nearCompletionChallenges.filter(
				(challengeId) => challengeId !== action.payload,
			);
		},
	},
});

export const { addNearCompletionChallenge, removeChallenge } =
	challengeProgress.actions;

export const selectNearCompletionChallenge =
	(challengeId: string) => (state: AppState) =>
		state.challengeProgress.nearCompletionChallenges.includes(challengeId);

export default challengeProgress;
