import { ClientContext as ClientContextType } from '@wearemojo/api-client';
import * as Localization from 'expo-localization';
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { getVendorId } from '../analytics/deviceIds';
import useBuildInfo from '../hooks/useBuildInfo';
import useIsMountedRef from '../hooks/useIsMountedRef';
import useIsReadyTimeout from '../hooks/useIsReadyTimeout';
import platform from '../utils/platform';

const createClientContext = (
	vendorId: string,
	buildInfo: ReturnType<typeof useBuildInfo>,
): ClientContextType => ({
	client: {
		platform,
		version: buildInfo.runtimeInfo.version ?? null,
		build: buildInfo.runtimeInfo.build ?? null,
		gitCommit: buildInfo.runtimeInfo.commitSha ?? null,
	},
	device: {
		vendorId,
	},
	locale: {
		timezone: Localization.timezone,
		languages: Localization.locales,
	},
});

export const ClientContext = createContext<ClientContextType | undefined>(
	undefined,
);

export const useClientContext = () => {
	return useContext(ClientContext);
};

export const ClientContextProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const clientContext = useCreateClientContext();
	const isReady = useIsReadyTimeout(
		{ isClientContextPending: !clientContext },
		{ timeoutMs: 100 }, // resolves almost immediately, but lets avoid re-render
	);
	if (!isReady) {
		return null;
	}

	return (
		<ClientContext.Provider value={clientContext}>
			{children}
		</ClientContext.Provider>
	);
};

const useCreateClientContext = () => {
	const [vendorId, setVendorId] = useState<string>();
	const buildInfo = useBuildInfo();
	const isMountedRef = useIsMountedRef();

	useEffect(() => {
		(async () => {
			const id = await getVendorId();

			if (isMountedRef.current) {
				setVendorId(id);
			}
		})();
	}, [isMountedRef]);

	return useMemo(() => {
		if (!vendorId) {
			return;
		}

		return createClientContext(vendorId, buildInfo);
	}, [vendorId, buildInfo]);
};

export default ClientContextProvider;
