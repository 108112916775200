import React, { ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ApiGate from '../components/ApiGate';
import { persistor, store } from '../store';

const StateProviders = ({ children }: { children: ReactNode }) => (
	<ReduxProvider store={store}>
		<PersistGate persistor={persistor}>
			<ApiGate>{children}</ApiGate>
		</PersistGate>
	</ReduxProvider>
);

export default StateProviders;
