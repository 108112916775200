import { skipToken } from '@reduxjs/toolkit/query';

import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectUserId } from '../../session';
import api from '..';

const useSignupAttributionStatus = () => {
	const userId = useAppSelector(selectUserId);

	return useEndpointQuery(
		api.endpoints.getSignupAttributionStatus.useQuery(
			userId ? { userId } : skipToken,
		),
	);
};

export default useSignupAttributionStatus;
