import { Service } from '@wearemojo/api-client';
import Cher from '@wearemojo/cher';
import ksuid from '@wearemojo/ksuid';
import { DevicePushToken } from 'expo-notifications';
import { useCallback } from 'react';

import { AnalyticsProvider, TrackEventHook } from '../../analytics/types';
import { useApiClient } from '../../api';
import { logger } from '../logging';

export const AnalyticsProviderMojo: AnalyticsProvider = ({ children }) => {
	// no setup needed
	return <>{children}</>;
};

export const useTrackEventMojo: TrackEventHook = () => {
	const apiClient = useApiClient();
	const sessionId = apiClient.getAuthSession().sessionId;
	return useCallback(
		(eventName, payload) => {
			const event = {
				type: eventName,
				idempotencyKey: ksuid.generate('idem').toString(),
				timestamp: new Date().toISOString(),
				meta: payload ?? null,
			};

			(async () => {
				try {
					if (!sessionId) {
						throw new Error('No session id');
					}

					logger.logAnalyticsEvent(event);

					await apiClient.request(Service.analyticsevent, 'recordEvents', {
						sessionId,
						events: [event],
					});
				} catch (error) {
					// console.error('Error recording (mojo) analytics event', error);
				}
			})();
		},
		[apiClient, sessionId],
	);
};

export const useRecordPushTokenMojo = () => {
	const apiClient = useApiClient();
	const sessionId = apiClient.getAuthSession().sessionId;
	return useCallback(
		(token: DevicePushToken) => {
			if (!sessionId) {
				logger.captureError('Error recording (mojo) push token', {
					error: new Error('No session id'),
				});
				return;
			}

			switch (token.type) {
				case 'android':
				case 'ios':
					(async () => {
						try {
							await apiClient.request(Service.pushnotification, 'recordToken', {
								sessionId,
								platform: token.type,
								token: token.data,
							});
						} catch (error) {
							if (Cher.isCode(error, 'already_exists')) {
								// No need to log error if failed due to already_exists
								return;
							}
							logger.captureError('Error recording (mojo) push token', {
								error,
							});
						}
					})();
					break;

				case 'web':
					(async () => {
						try {
							await apiClient.request(
								Service.pushnotification,
								'recordWebSubscription',
								{
									session_id: sessionId,
									platform: token.type,
									endpoint: token.data.endpoint,
									keys: token.data.keys,
								},
								{
									disableRequestSnek: true,
								},
							);
						} catch (error) {
							logger.captureError(
								'Error recording (mojo) push web subscription',
								{ error },
							);
						}
					})();
					break;
			}
		},
		[apiClient, sessionId],
	);
};
