import { createSlice } from '@reduxjs/toolkit';

import { AppState, Navigation } from './types';

const initialState: Navigation = {
	isShowingWelcomeScreen: false,
};

const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		setIsShowingWelcomeScreen: (state, action: { payload: boolean }) => {
			state.isShowingWelcomeScreen = action.payload;
		},
	},
});
export const { setIsShowingWelcomeScreen } = navigationSlice.actions;
export const selectIsShowingWelcomeScreen = (state: AppState) =>
	state.navigation.isShowingWelcomeScreen;

export default navigationSlice;
