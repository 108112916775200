import { ApiStateContext, getComponentName } from '@wearemojo/ui-components';
import { ComponentType, useMemo } from 'react';

import { useFeature } from '../hooks/useFeature';
import { useNotificationPermission } from '../notifications';

export default function withApiState<P extends JSX.IntrinsicAttributes>(
	Component: ComponentType<P>,
) {
	function WithApiStateProvider(props: P) {
		const preferDirectVideo = useFeature('prefer_direct_video');
		const [notificationPermission, notificationRequest] =
			useNotificationPermission();
		const { granted } = notificationPermission ?? {};

		const state = useMemo(
			() => ({
				notificationPermission: {
					state: granted == null ? undefined : { granted },
					request: notificationRequest,
				},
				preferDirectVideo: {
					isOn: preferDirectVideo.isOn,
					isLoading: preferDirectVideo.isLoading,
				},
			}),
			[
				granted,
				notificationRequest,
				preferDirectVideo.isLoading,
				preferDirectVideo.isOn,
			],
		);

		return (
			<ApiStateContext.Provider value={state}>
				<Component {...props} />
			</ApiStateContext.Provider>
		);
	}
	WithApiStateProvider.displayName = getComponentName(Component);

	return WithApiStateProvider;
}
