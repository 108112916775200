import * as Application from 'expo-application';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';

export const getRuntimeInfo = () => {
	const { nativeApplicationVersion, nativeBuildVersion } = Application;

	const version = nativeApplicationVersion || Constants.expoConfig?.version;
	const build = nativeBuildVersion || undefined;
	const commitSha = Constants.expoConfig?.extra?.commitSha;

	if (!version) {
		throw new Error('Could not determine app version');
	}

	return { version, build, commitSha };
};

export const getUpdateInfo = () => {
	const { channel, updateId, isEmergencyLaunch } = Updates;

	if (!channel) {
		return;
	}

	return {
		channel,
		updateId: updateId ?? undefined,
		isEmergencyLaunch,
	};
};
