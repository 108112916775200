import { UIPlatform } from '@wearemojo/ui-constants';
import * as Notifications from 'expo-notifications';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Linking } from 'react-native';

import { AnalyticsEvent } from '../analytics';
import { useTrackEvent } from '../analytics/trackEvent';
import { useApiClient } from '../api';
import { useSendTokenToCustomerio } from '../utils/analytics/customerio';
import {
	useSendTokenToIntercom,
	useSetupPushHandlingIntercom,
} from '../utils/analytics/intercom';
import { useRecordPushTokenMojo } from '../utils/analytics/mojo';
import { logger } from '../utils/logging';
import platform from '../utils/platform';

Notifications.setNotificationHandler({
	handleNotification: async (_: Notifications.Notification) => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false,
	}),
});

if (platform === UIPlatform.android) {
	Notifications.setNotificationChannelAsync('default', {
		name: 'default',
		importance: Notifications.AndroidImportance.MAX,
	});

	Notifications.setNotificationChannelAsync('intercom_chat_replies_channel', {
		name: 'Support messages',
		description: 'Notifications for messages from customer support',
		importance: Notifications.AndroidImportance.MAX,
	});
}

export const useSetupNotifications = () => {
	useSetupPushHandling();
	useRegisterForPushNotifications();
};

export const useSetupPushHandling = () => {
	useSetupPushHandlingIntercom();
};

export const useNotificationPermission = (): [
	Notifications.NotificationPermissionsStatus | undefined,
	() => void,
] => {
	const trackEvent = useTrackEvent();
	const requested = useRef(false);
	const [permission, request] = Notifications.usePermissions();

	const wrappedRequest = useCallback(() => {
		requested.current = true;

		trackEvent(AnalyticsEvent.notification_permission_requested, {});

		if (!permission?.canAskAgain) {
			Linking.openSettings();
		}

		request();
	}, [trackEvent, request, permission?.canAskAgain]);

	useEffect(() => {
		if (!permission?.granted) return;

		if (!requested.current) return;
		requested.current = false;

		trackEvent(AnalyticsEvent.notification_permission_granted, {});

		// `useNotificationToken` is listening for the token
		Notifications.getDevicePushTokenAsync();
	}, [trackEvent, permission?.granted]);

	return useMemo(
		() => [permission ?? undefined, wrappedRequest],
		[permission, wrappedRequest],
	);
};

export const useNotificationToken = () => {
	const [permission] = Notifications.usePermissions();
	const [token, setToken] = useState<Notifications.DevicePushToken>();

	useEffect(() => {
		if (permission?.granted) {
			Notifications.getDevicePushTokenAsync().then(setToken);
		}
		return Notifications.addPushTokenListener(setToken).remove;
	}, [permission?.granted]);

	return token;
};

export const useRegisterForPushNotifications = () => {
	const sendTokenToCustomerio = useSendTokenToCustomerio();
	const sendTokenToIntercom = useSendTokenToIntercom();
	const recordPushTokenMojo = useRecordPushTokenMojo();
	const token = useNotificationToken();
	const apiClient = useApiClient();
	const sessionId = apiClient.getAuthSession().sessionId;

	useEffect(() => {
		if (!token || !sessionId) return;
		const providers = [
			sendTokenToCustomerio,
			sendTokenToIntercom,
			recordPushTokenMojo,
		];
		for (const provider of providers) {
			try {
				provider(token);
			} catch (e) {
				logger.captureError(
					'[notifications.registerForPushNotifications] provider failed',
					{ e },
				);
			}
		}
	}, [
		recordPushTokenMojo,
		sendTokenToCustomerio,
		sendTokenToIntercom,
		sessionId,
		token,
	]);
};
