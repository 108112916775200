import { SubscriptionStatus } from '@wearemojo/api-client';
import { createContext, ReactNode, useContext } from 'react';

import useSubscriptionStatus from '../store/api/hooks/useSubscriptionStatus';
type SubscriptionStateContextProps = ReturnType<
	typeof useSubscriptionStatus
>['data'];

const SubscriptionStateContext = createContext<
	SubscriptionStateContextProps | undefined
>(undefined);

const SubscriptionStateProvider = ({ children }: { children: ReactNode }) => {
	const { data: subscriptionState } = useSubscriptionStatus();
	return (
		<SubscriptionStateContext.Provider value={subscriptionState}>
			{children}
		</SubscriptionStateContext.Provider>
	);
};

export const useSubscriptionContext = () => {
	return useContext(SubscriptionStateContext);
};

export const useSubscriptionSituation = () => {
	const state = useSubscriptionContext();
	if (state === undefined) return {};

	return {
		hasSubscription: !!state,
		isFreeUser: !state,
		isActiveMember: state ? state.isActiveMember : false,
		isPastDue: state?.status === SubscriptionStatus.PastDue,
		hasCompletedTrial: state?.status === SubscriptionStatus.Active,
		cancelAtPeriodEnd: state ? state.cancelAtPeriodEnd : false,
		hasIapSubscription: state?.provider === 'revenue_cat',
		store: state?.store,
	};
};

export default SubscriptionStateProvider;
