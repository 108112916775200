import { SanityClient } from '@sanity/client';
import { ApiClient as MojoApiClient } from '@wearemojo/api-client';

export enum ApiClient {
	sanity = 'sanity',
	mojo = 'mojo',
}

type ApiClientMapping = {
	[ApiClient.sanity]: SanityClient;
	[ApiClient.mojo]: MojoApiClient;
};

class ApiClients {
	private static instance: ApiClients;
	private clients: Partial<ApiClientMapping> = {};

	static getClient<Name extends ApiClient>(name: Name): ApiClientMapping[Name] {
		return this.getInstance().getClient(name);
	}

	static setClient<
		Name extends ApiClient,
		Instance extends ApiClientMapping[Name],
	>(name: Name, instance: Instance) {
		return this.getInstance().setClient(name, instance);
	}

	private static getInstance() {
		if (!ApiClients.instance) {
			ApiClients.instance = new ApiClients();
		}

		return ApiClients.instance;
	}

	private getClient<Name extends ApiClient>(
		name: Name,
	): ApiClientMapping[Name] {
		const client = this.clients[name];
		if (!client) {
			throw new Error(
				`ApiClient.${name} not found, clients need to first be created then registered with ApiClients.setClient(ApiClient.${name}, client)`,
			);
		}
		return client as ApiClientMapping[Name];
	}

	private setClient(name: ApiClient, instance: any) {
		this.clients[name] = instance;
	}
}

export default ApiClients;
